
.topArrowCont {
  position: fixed;
  bottom: 3%;
  right: 3%;
  width: 50px;
  height: 50px;
  z-index: 4;

  .topArrow {
    width: 100%;
    z-index: 4;
    color: $darkBlue;
    font-size: 4rem;

    &:hover{
      color: $blue;
    }
  }
}