
.aboutCont {
  height: auto;
  width: 100%;
  background-color: #f7f7f7;
  @include dFlex(column, flex-start, center);
  padding: 0 10px 50px 10px;
  
  .aboutText {
    width: 70%;
    max-width: 100%;

    @include media(600px){
      width: 100%;
    }

    p {
      text-align: center;
    }
  }//end aboutText
}// end aboutCont