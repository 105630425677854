// mix-ins

@mixin dFlex($dir, $just, $align) {
  display: flex;
  flex-direction: $dir;
  justify-content: $just;
  align-items: $align;
}

@mixin buttonStyles {
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #aaa;
  font-size: $pSize;
  z-index: 2;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

//media queries
@mixin media($width) {
  @media only screen and (max-width: $width) {
    @content
  }
}

@mixin mediaMin($width) {
  @media only screen and (min-width: $width) {
    @content
  }
}