//google fonts
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');

.App {
  @include dFlex(column, center, center);
  text-align: center;
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;

  .sectionTitle {
    margin: 50px 0;
  }
}

//end App