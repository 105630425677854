.navMainCont {
    @include dFlex(row, center, center);
    height: auto;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 0 10% 0;

    @include media("320px") {
        padding: 0 2%;
    }

    @keyframes moveRight {
        from {
            margin-right: 200vw;
        }
        to {
            margin-right: 0;
        }
    }

    @keyframes marginRight {
        from {
            margin-right: 0;
        }
        to {
            margin-right: 5%;
        }
    }

    nav {
        z-index: 2;
        width: 100%;
        text-align: center;
        @include dFlex(row, center, center);
        animation-name: moveRight;
        animation-duration: 2s;
        animation-timing-function: ease;

        @include media("600px") {
            animation: none !important;
        }

        @include media("400px") {
            width: 100%;
        }

        ul {
            width: 100%;
            @include dFlex(row, center, center);
            list-style-type: none;

            @include media("400px") {
                @include dFlex(column, space-evenly, center);
            }

            li {
                animation-name: marginRight;
                animation-duration: 2s;
                margin-right: 5%;
                @include dFlex(row, space-evenly, center);

                @include media("600px") {
                    animation: none !important;
                }

                @include media("400px") {
                    margin-right: 0;
                    margin-bottom: 5px;
                    width: 100%;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.6);

                    &:first-child {
                        border-top: 1px solid rgba(0, 0, 0, 0.6);
                    }
                }

                &:last-child {
                    animation: none;
                    margin-right: 0;
                }

                a {
                    font-size: $h4Size;
                    color: $orange;

                    @include media("540px") {
                        font-size: $h5Size;
                    }

                    @include media("400px") {
                        height: 100%;
                        width: 100%;
                        font-size: $h5Size + 1;
                    }
                }

                a:hover {
                    color: lighten($orange, 50%);
                    margin-top: -5px;

                    @include media("400px") {
                        margin-top: 0;
                    }
                }
            } //end li
        } //end ul
    } //end nav
} //end navMainCont
