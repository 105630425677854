// projectCard styles

.projectCard {
    width: 30%;
    min-width: 30%;
    margin-bottom: 30px;
    padding-top: 2%;
    border: 1px solid Green;
    border-radius: 5px 5px 0 0;
    position: relative;

    @include media(870px) {
        margin: 10px;
        width: 45%;
    }

    @include media(610px) {
        width: 100%;
        margin: 0 auto 30px auto;
    }

    // p {
    //     font-size: $pSizeSmall;
    //     padding: 4%;
    //     color: #ddd;
    //     z-index: 2;

    //     @include media("610px") {
    //         font-size: $pSizeSmall;
    //     }
    // }

    img {
        width: 100%;
    }

    .linksCont {
        width: 100%;
        padding: 0 1%;
        @include dFlex(row, space-evenly, center);
        flex-wrap: wrap;

        p.privateRepo {
            color: $darkGray;
            font-size: $aSize;
            padding: 0;
            margin: 0;
        }

        a {
            margin-right: 1%;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
