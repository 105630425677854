// contact styles

.contactCont {
  @include dFlex(column, flex-start, center);
  height: auto;
  min-width: 100%;
  padding: 0 100px 5% 100px;
  background-color: #f7f7f7;

  @include media("600px") {
    padding: 0 20px 5% 20px;
  }

  ul.contactLinksCont {
    @include dFlex(row, center, center);
    width: 100%;
    list-style-type: none;

    @include media("750px") {
      @include dFlex(row, space-evenly, center);
      flex-wrap: wrap;
    }

    li {
      margin: 0 10% 50px 0;
      list-style-type: none;

      &:last-child {
        margin-right: 0;
      }

      .contactIcon {
        font-size: 4rem;
      }

      a.contactLinks {
        color: $pColor;
        font-size: $aSize;

        &:hover {
          cursor: pointer;
          color: #333;
        }
      }
    }
  }
}
