.headerCont {
    width: 100%;
    max-width: 100%;
    height: auto;
    background-color: $black;
    margin-bottom: 5%;
    padding: 8% 2% 0 2%;
    position: relative;

    .slogan {
        width: 100%;
        max-width: 100%;
        font-style: italic;
        margin: 25px auto 20px auto;
        letter-spacing: 1rem;

        h3 {
            color: $white;
        }

        // @include media(810px) {
        //   width: 40%;
        // }

        @include media(320px) {
            display: none;
        }
    }

    #tsparticles {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
    }

    hr {
        background-image: linear-gradient(
            90deg,
            transparent,
            white,
            transparent
        );
        border: 0;
        height: 1px;
        margin: 10px auto;
        width: 80%;
    }
}
