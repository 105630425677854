// ModalCont styles

.modalCont {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6;
  width: 100%;
  height: 100vh;
  padding: 2% 2% 3% 2%;
  background-color: rgba(0, 0, 0, 0.6);

  .modalClose {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 2%;
    right: 2%;
    height: 30px;
    width: 30px;
    border-radius: 5px;
    border: 1px solid black;
    font-size: 2rem;
    background-color: rgba(0, 0, 0, 0.4);
    color: #fff;
    cursor: pointer;
    z-index: 6;

    &:hover {
      color: rgba(0, 0, 0, 0.8);
      background-color: #ddd;
    }
  }

  video {
    margin: 0 auto;
    width: 80%;

    @include media('700px'){
      width: 100%;
    }

    @include media('812px'){
      height: 100vh;
    }
  }
}
