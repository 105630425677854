
// THEME COLORS ******
$blue: rgb(137, 156, 216);
$darkBlue: rgb(128, 157, 255);
$orange: #B16B46;
$transBlack: rgba(0, 0, 0, 0.8);
$gray: rgb(150, 150, 150); //p color
$darkGray: rgb(97, 96, 96);
$black: #222; //top section background
$white: #ccc; //h1 color, anything white
$red: red;

// ********************* FONTS ********************* //

// FONT COLORS *****
$pColor: $gray;
$aColor: $orange;

$h1Color: $white;
$h2Color: $blue;
$h3Color: $white;
$h4Color: $orange; 
$h5Color: lighten($black, 30%);

$labelColor: $black;
$inputColor: $darkGray;


//  FONT SIZES *****

$pSizeSmall: 1.6rem;
$pSize: 2rem;
$pLarge: 3rem;

$aSize: 2.2rem;
$aLarge: 3rem;

$h1Size: 4.8rem;
$h1Large: 6.2rem;

$h2Size: 4.2rem;
$h2Large: 4.6rem;

$h3Size: 2rem;
$h3Large: 3rem;

$h4Size: 3.5rem; // fieldset legned also use this
$h4Large: 5rem;
//was 2.8rem @ 1280 x 800px

$h5Size: 2.2rem;
$h5Large: 4.8rem;